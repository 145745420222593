<template>
    <tbody>
        <tr
            :class="`${isStatusClosed(item) ? 'disabled' : ''}`"
            v-for="(item, index) in items"
            :key="index"
        >
            <!-- === === INDEX === === -->
            <td class="truncate">{{ item.index }}</td>
            <!-- === === LEASING NUMBER === === -->

            <td class="truncate">
                {{ item.leasingNumber }}
            </td>
            <!-- === === CONTACT === === -->
            <td class="truncate">
                <!-- {{  }} -->
                <!-- • -->
                <DoubleLine
                    :firtstLine="$get(item.contact, 'name', '')"
                    :secondLine="checkPhoneNumber(item)"
                />
            </td>
            <!-- === === START DATE === === -->
            <td class="truncate">
                {{
                    item.startDate
                        ? $moment(item.startDate).format(`DD-MM-YYYY`)
                        : $t('global:empty')
                }}
            </td>
            <!-- === === END DATE === === -->
            <td class="truncate">
                {{
                    item.endDate
                        ? $moment(item.endDate).format(`DD-MM-YYYY`)
                        : $t('global:empty')
                }}
            </td>
            <!-- === === MONTH WORTH === === -->
            <td class="truncate">
                {{ monthWorth(item.monthWorth) }}
            </td>
            <!-- === === STATUS === === -->
            <td>
                <v-chip
                    :color="$get(item, 'status.color.background', '#555555')"
                    :style="`color: ${$get(
                        item,
                        'status.color.font',
                        '#FFFFFF'
                    )}`"
                    dark
                    label
                    small
                    >{{ $get(item, 'status.name', $t('users:empty')) }}</v-chip
                >
            </td>
            <td class="d-flex align-center justify-end">
                <v-icon small v-if="hasFile(item.folders)"
                    >mdi-paperclip</v-icon
                >
                <v-menu bottom v-if="remove()">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on">
                            <v-icon color="iconGray" small
                                >mdi-dots-vertical</v-icon
                            >
                        </v-btn>
                    </template>
                    <v-list dense>
                        <!-- === === REMOVE === === -->
                        <v-list-item @click="openConfirmModal(item._id)">
                            <v-list-item-icon class="mr-0">
                                <v-icon color="red" small>mdi-delete</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Usuń</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-btn small router :to="`/leasings/${item._id}`" icon>
                    <v-icon color="secondary" small
                        >mdi-arrow-right-thick</v-icon
                    >
                </v-btn>
            </td>
        </tr>
        <ConfirmModal
            :openConfirm="openConfirm"
            @close-confirm="closeConfirmModal"
        >
            <div slot="main"><ConfirmContent /></div>
            <div slot="buttons">
                <div class="d-flex justify-center">
                    <v-btn
                        color="rgb(117, 117, 117)"
                        width="110"
                        height="40"
                        @click="closeConfirmModal()"
                        class="white--text mx-2"
                        >{{ $t('global:cancel') }}</v-btn
                    >
                    <v-btn
                        width="110"
                        height="40"
                        color="#445d75"
                        class="white--text mx-2"
                        @click="deleteItem()"
                        >{{ $t('global:remove') }}</v-btn
                    >
                </div>
            </div>
        </ConfirmModal>
    </tbody>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ConfirmContent from '../../Departments/ConfirmModal/ConfirmContent.vue'
export default {
    props: ['items'],
    data: () => ({
        openConfirm: false,
        removeItemID: '',
    }),
    components: {
        ConfirmContent,
    },
    computed: {
        ...mapGetters(['getProfileDetails']),
    },
    methods: {
        monthWorth(val) {
            let t = val.toFixed(2).toString().replace('.', ',')
            return t + ' PLN'
        },
        checkPhoneNumber(item) {
            if (this.$get(item.representative, `${'phoneNumber'}`, '')) {
                return `${this.$get(
                    item.representative,
                    'name',
                    ''
                )} ${this.$get(
                    item.representative,
                    'lastname',
                    ''
                )} • ${this.$get(item.representative, `${'phoneNumber'}`, '')}`
            } else {
                return `${this.$get(
                    item.representative,
                    'name',
                    ''
                )} ${this.$get(item.representative, 'lastname', '')}`
            }
        },
        ...mapActions(['deleteLeasing']),
        openConfirmModal(id) {
            this.removeItemID = id
            this.openConfirm = true
        },
        closeConfirmModal() {
            this.openConfirm = false
        },
        async deleteItem() {
            let result = await this.deleteLeasing({ id: this.removeItemID })
            if (result) this.openConfirm = false
        },
        remove() {
            return this.checkPermissions('DELETE')
        },
    },
}
</script>
