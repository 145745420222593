<template>
    <v-container class="p-relative">
        <v-row>
            <v-col cols="12" md="12" class="py-0">
                <RegistryHeader
                    :title="getLeasingRegistry.name"
                    :avatarData="getLeasingRegistry.currentMenager"
                    @clearSearch="clearTable"
                />
                <Table
                :title="getLeasingRegistry.name"
                    :items="getLeasingTable.items"
                    :headers="headers"
                    :length="getLeasingTable.pages"
                    :page="getLeasingTable.page"
                    @search="setSearch"
                    @clear-table="clearTable"
                    @search-fields="setSearchFields"
                    @change-page="setPage"
                    @sort="setSort"
                    :defaultFields="getLeasingsSearchFields.searchFields"
                    :loading="getLeasingTable.loading"
                >
                    <Columns slot="columns" :items="getLeasingTable.items" />
                </Table>
                <Modal
                    :title="$t('leasings:additionLeasing')"
                    :open="open"
                    :height="'520'"
                    v-on:close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        :action="'create'"
                        slot="buttons"
                        @closeModal="open = false"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--action">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                data-cy="addEntry"
                                fab
                                dark
                                v-on="on"
                                small
                                v-if="add()"
                                @click="addLeasing()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('leasings:addLeasing') }}</span>
                    </v-tooltip>
                </div></v-col
            >
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import store from './../../../store/index'
import Columns from './../../../components/Registries/Leasing/Table/Columns'
import AEContent from './../../../components/Registries/Leasing/Modal/Content'
import Buttons from './../../../components/Registries/Leasing/Modal/Buttons'
export default {
    data() {
        return {
            open: false,
            headers: [
                {
                    text: this.$t('leasings:index'),
                    value: 'index',
                    width: '9%',
                    showSearch: true,
                },
                {
                    text: this.$t('leasings:leasingNumber'),
                    value: 'leasingNumber',
                    width: '10%',
                    showSearch: true,
                },
                {
                    text: this.$t('leasings:contact.default'),
                    value: 'contact',
                    width: '35%',
                    showSearch: true,
                },
                {
                    text: this.$t('leasings:startDate'),
                    value: 'startDate',
                    width: '10%',
                },
                {
                    text: this.$t('leasings:endDate'),
                    value: 'endDate',
                    width: '10%',
                },
                {
                    text: this.$t('leasings:monthWorth'),
                    value: 'monthWorth',
                    width: '14%',
                    align: 'left',
                },
                {
                    text: this.$t('leasings:status'),
                    value: 'status',
                    width: '100px',
                },
                {
                    text: '',
                    value: 'arrow',
                    align: 'right',
                    width: '150px',
                    sortable: false,
                },
            ],
        }
    },
    components: {
        Columns,
        AEContent,
        Buttons,
    },
    computed: {
        ...mapGetters([
            'getLeasingTable',
            'getLeasingsSearchFields',
            'getLeasingRegistry',
            'getSearch',
        ]),
    },
    methods: {
        ...mapActions(['fetchUsers', 'fetchLeasingTable']),
        ...mapMutations([
            'clearLeasingModal',
            'clearLeasingErrors',
            'clearContactInput',
            'clearRepresentativesInput',
            'setLeasingTable',
            'setLeasingSearch',
            'clearLeasingTable',
        ]),
        closeModal() {
            this.clearRepresentativesInput()
            this.clearContactInput()
            this.open = false
        },
        add() {
            return this.checkPermissions('CREATE')
        },
        async addLeasing() {
            this.open = true
            this.clearLeasingErrors()
            this.clearLeasingModal()
        },
        setSearchFields(searchFields) {
            this.setLeasingSearch(searchFields)
        },
        setSearch(search) {
            this.setLeasingTable({ search, page: 1 })
            this.fetchLeasingTable()
        },
        setPage(page) {
            this.setLeasingTable({ page })
            this.fetchLeasingTable()
        },
        setSort(sort) {
            this.setLeasingTable({ sort })
            this.fetchLeasingTable()
        },
        clearTable() {
            this.getSearch.text = ''
            this.clearLeasingTable()
            this.fetchLeasingTable()
        },
    },
    beforeRouteEnter(to, from, next) {
        if (from.matched[1] && from.matched[1].path !== '/leasings/:id')
            store.commit('clearLeasingTable')
        store.dispatch('fetchLeasingTable').then(() => next())
    },
}
</script>
